import { useState } from 'react';
import DropdownMenuItem, {
  DropdownMenuItemVariants,
} from '@/components/molecules/DropdownMenuItem';
import { POPULAR_SEARCHES, POPULAR_SEARCH_MAKES } from '@/lib/constants';
import { BodyTypeOption } from '@/lib/schema/inventory/types';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import useWindowSize from '@/hooks/useWindowSize';
import { MakeIcon } from '@/components/atoms/MakeIcons/MakeIcon';
import { ClockIcon } from '@heroicons/react/24/outline';

interface RecentSearchProps {
  showCount: boolean;
  variant: DropdownMenuItemVariants;
  value: string[];
  onChange: (value: string[]) => void;
  open: boolean;
  recentSearch: string[];
}

export const SEARCH_BODY_TYPE_OPTIONS: BodyTypeOption[] = [
  {
    icon: {
      iconId: IconIds.Suv,
      className: 'h-[24px] w-[32px]',
    },
    value: 'SUV',
  },
  {
    icon: {
      iconId: IconIds.Sedan,
      className: 'h-[24px] w-[32px]',
    },
    value: 'Sedan',
  },
  {
    icon: {
      iconId: IconIds.Coupe,
      className: 'h-[24px] w-[32px]',
    },
    value: 'Coupe',
  },
  {
    icon: {
      iconId: IconIds.Truck,
      className: 'h-[24px] w-[32px]',
    },
    value: 'Truck',
  },
];

export const RecentSearch = ({
  showCount,
  variant,
  value,
  onChange,
  recentSearch,
}: RecentSearchProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { isMobile } = useWindowSize();
  const popularMakes = isMobile
    ? POPULAR_SEARCH_MAKES.slice(0, 4)
    : POPULAR_SEARCH_MAKES;

  const reversedRecentSearch = recentSearch.slice().reverse();

  return (
    <div className="flex flex-col gap-l">
      {reversedRecentSearch && reversedRecentSearch.length > 0 && (
        <div className="flex flex-col gap-s">
          {reversedRecentSearch.map((recent, index) => {
            return (
              <DropdownMenuItem
                key={`recent-search-dropdown-${index}`}
                text={recent || ''}
                variant={variant}
                showCount={showCount}
                onChange={() => {
                  onChange([recent]);
                }}
                selected={value.includes(recent)}
                iconNode={<ClockIcon className={'mr-s flex h-l w-l'} />}
              />
            );
          })}
          <div className="hidden h-full border-[1px] border-solid border-neutral-200 m:block"></div>
        </div>
      )}
      <div className="flex flex-col-reverse gap-l m:flex-col">
        <div className="flex flex-col gap-s">
          <span className="block pt-s text-microMedium text-brandMidnight">
            Popular Searches
          </span>
          {POPULAR_SEARCHES.map((searchOption, index) => {
            return (
              <DropdownMenuItem
                key={`dropdown-${searchOption.make}-${searchOption.model}-${index}`}
                text={searchOption.value || ''}
                variant={variant}
                showCount={showCount}
                onChange={() => {
                  onChange([searchOption.value]);
                }}
                selected={value.includes(searchOption.value)}
              />
            );
          })}
          <div className="hidden h-full border-[1px] border-solid border-neutral-200 m:block"></div>
        </div>

        <div className="flex flex-col gap-s m:hidden">
          <span className="block pt-s text-microMedium text-brandMidnight">
            Search by body type
          </span>
          <div className="flex justify-around gap-s">
            {SEARCH_BODY_TYPE_OPTIONS.map((option, index) => {
              return (
                <div
                  onClick={() => {
                    onChange([option.value]);
                  }}
                  key={`${option.icon.iconId}-${index}`}
                  aria-label={`filter by ${option.value}`}
                  className="flex cursor-pointer flex-col items-center justify-center gap-s rounded-small border border-neutral-100 bg-neutral-100 p-l hover:border-brandElectric hover:bg-green-light hover:text-brandMidnight"
                  onTouchStart={() => setHoveredIndex(index)}
                  onTouchEnd={() => setHoveredIndex(null)}
                >
                  <Icon
                    iconId={
                      hoveredIndex === index
                        ? (`${option.icon.iconId}Solid` as IconIds)
                        : option.icon.iconId
                    }
                    className={option.icon.className}
                  />
                  <div className="text-body1Light text-neutral-800">
                    {option.value}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-s">
          <span className="block pt-s text-microMedium text-brandMidnight">
            Search by popular makes
          </span>
          <div className="flex justify-around">
            {popularMakes.map((popularMake, index) => {
              return (
                <div
                  key={`popular-makes-${popularMake.make}-${index}`}
                  onClick={() => {
                    onChange([popularMake.value]);
                  }}
                  className="flex cursor-pointer flex-col justify-center gap-s"
                >
                  <MakeIcon
                    className="relative z-10 flex aspect-square items-center justify-center rounded-full bg-neutral-100 p-l shadow-s hover:shadow-l"
                    width={32}
                    height={32}
                    make={popularMake.make}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
