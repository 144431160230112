import Checkbox from '@/components/atoms/legacy/Checkbox';
import { ScComponent } from '@/types/interfaces';
import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react';

export enum DropdownMenuItemVariants {
  NoSelect = 'no-select',
  MultiSelect = 'multi-select',
  SingleSelect = 'single-select',
}

export interface DropdownMenuItemProps extends ScComponent {
  variant?: DropdownMenuItemVariants;
  text: string;
  count?: number;
  selected: boolean;
  showCount?: boolean;
  onChange: () => void;
  iconNode?: React.ReactNode;
}

const DropdownMenuItem: React.FC<DropdownMenuItemProps> = ({
  className = '',
  variant = DropdownMenuItemVariants.SingleSelect,
  text,
  count,
  selected,
  onChange = () => {},
  showCount = true,
  iconNode,
}) => {
  return (
    <label
      className={`flex w-full cursor-pointer items-center rounded-small px-[14px] py-[10px] text-body1Light text-neutral-900 hover:bg-neutral-200 ${
        selected ? 'bg-blue-light text-brandMidnight' : ''
      } ${className}`}
      onClick={() => {
        if (variant === DropdownMenuItemVariants.NoSelect) onChange();
      }}
    >
      {variant !== DropdownMenuItemVariants.NoSelect && (
        <Checkbox
          className={`${
            variant === DropdownMenuItemVariants.MultiSelect ? '' : 'hidden'
          } mr-[8px]`}
          checked={selected}
          onChange={onChange}
        />
      )}
      {iconNode}
      <span>{text}</span>
      <section className="ml-auto flex items-center justify-between space-x-[10px]">
        {typeof count === 'number' && showCount && (
          <span className="inline-block rounded-full bg-blueGrey-100 px-[4.5px] py-[2px] text-micro3 text-blueGrey-0">
            {count}
          </span>
        )}
        {variant === DropdownMenuItemVariants.SingleSelect && selected && (
          <CheckIcon width={20} height={20} stroke="#516670" />
        )}
      </section>
    </label>
  );
};

export default DropdownMenuItem;
